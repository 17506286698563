<template>
    <div class="login-wrap">
        <div class="login-box">
            <div class="logo"></div>
            <Divider>项目数据看板</Divider>
            <div class="form">
                <Input type="tel" v-model="phone" prefix="md-phone-portrait" :border="false" clearable placeholder="请输入手机号" maxlength="11"></Input>
                <Button type="primary" @click="handleSubmit">手机号一键登录</Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            phone: ''
        }
    },
    methods: {
        handleSubmit() {
            if(!this.phone){
                this.$Message.warning("请输入手机号");
                return
            }
            if(/^1[3456789]\d{9}$/g.test(this.phone)){
                this.$http.get("/dashboard/auth", {phone: this.phone}).then(rs=>{
                    this.$cookie.set("otoken",rs);
                    this.$router.push("/");
                })
            }else{
                this.$Message.warning("手机号格式错误");
            }
        }
    }
}
</script>

<style scoped>
    .login-wrap{
        height: 100vh;
        width: 100%;
        background: linear-gradient(135deg,#b3c5c4,#ffe7e7 100%,#fae2b7 0);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 10%;
    }
    .login-box{
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(255, 255, 255, .8);
        padding: 60px;
        border-radius: 20px;
    }
    .logo{
        width: 90px;
        height: 90px;
        background: url("../assets/logo.png") center no-repeat;
	    background-size: contain;
    }
    .ivu-divider{
        color: #555;
        font-size: 13px;
        margin-bottom: 50px;
    }
    .form{
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ivu-input-wrapper{
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 30px;
    }
    ::v-deep .ivu-input{
        background-color: transparent;
    }
</style>